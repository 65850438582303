import React, { useRef, useState, useEffect } from 'react'
import { Box, Flex } from 'reflexbox'
import Image from 'src/components/UI/Image/Image'
import * as styles from './StoryblokVideo.module.scss'
import classnames from 'classnames'
import { useVideoPlayer } from 'src/helpers/hooks'
import isNode from 'is-node'

const StoryblokVideo = (props: any) => {
  const [animate, setAnimate] = useState(false)
  const { blok, className, onClick } = props
  const { placeholder, video, caption, _uid } = blok
  const { filename = '' } = video || {}
  const [videoStarted, setVideoStarted] = useState(false)
  const videoElement = useRef(null)
  const { togglePlay, setPaused, playerState } = useVideoPlayer(videoElement)
  const { isPlaying } = playerState || {}
  const extension = filename
    ? filename.substr(filename.lastIndexOf('.') + 1)
    : null

  const onPlay = () => {
    togglePlay()
    if (!videoStarted) {
      setVideoStarted(true)
    }
  }

  const observeHandler = (entries: any) => {
    for (const entry of entries) {
      if (!entry.isIntersecting) {
        entry.target.pause()
      }
    }
  }

  const observer = !isNode
    ? new IntersectionObserver(observeHandler, {
        rootMargin: '0px 0px 0px 0px',
      })
    : null

  useEffect(() => {
    if (videoElement && videoElement.current) {
      observer?.observe(videoElement.current)
    }
    return () => {
      observer?.disconnect()
    }
  }, [])

  if (filename && extension && extension === 'mp4') {
    return (
      <React.Fragment>
        <Box
          className={classnames(
            styles.videoWrapper,
            className,
            animate ? styles.animate : ''
          )}
          onClick={() => {
            onPlay()
            if (!animate) {
              setAnimate(true)
            }
            if (onClick) {
              onClick()
            }
          }}
          onAnimationEnd={() => {
            if (animate) {
              setAnimate(false)
            }
          }}
        >
          <Flex
            alignItems="flex-end"
            justifyContent="flex-end"
            padding={8}
            className={
              videoStarted
                ? [styles.poster, styles.hidden].join(' ')
                : styles.poster
            }
          >
            {placeholder?.filename && (
              <Image
                className={classnames(videoStarted && styles.hidden)}
                src={placeholder.filename}
                alt={placeholder.title}
              />
            )}
            <Flex
              as="span"
              justifyContent="center"
              alignItems="center"
              className={styles.icon}
            >
              {!isPlaying && (
                <img className={styles.play} src="/images/play.svg" />
              )}
              {isPlaying && (
                <img className={styles.pause} src="/images/pause.svg" />
              )}
            </Flex>
          </Flex>
          <video
            id={_uid}
            ref={videoElement}
            loop
            playsInline
            onPause={() => {
              setPaused()
            }}
            onPlay={() => {
              const videoElements: any = document.getElementsByTagName('video')
              for (let item of videoElements) {
                if (item.id !== _uid) {
                  item.pause()
                }
              }
            }}
            controls={false}
            //    poster={placeholder?.filename ? placeholder.filename : null}
          >
            <source src={filename} type="video/mp4" />
            Your browser does not support the video tag.
          </video>
        </Box>
        {caption && (
          <Box className={styles.caption} marginTop={4}>
            {caption}
          </Box>
        )}
      </React.Fragment>
    )
  } else {
    return (
      <Flex
        className={styles.placeholder}
        width={1}
        justifyContent="center"
        alignItems="center"
      >
        Upload mp4 video format
      </Flex>
    )
  }
}

export default StoryblokVideo

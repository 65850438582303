import React from 'react'
import Image from 'src/components/UI/Image/Image'
import { Box } from 'reflexbox'
import classnames from 'classnames'
import * as styles from './StoryblokImage.module.scss'
const StoryblokImage = ({
  blok,
  className,
}: {
  blok: any
  className?: string
}) => {
  const { image, caption } = blok

  return image && image.filename ? (
    <React.Fragment>
      <Box className={classnames(styles.wrapper, className ? className : '')}>
        <Image src={image.filename} alt={image.title} />
      </Box>
      {caption && (
        <Box className={styles.caption} marginTop={4}>
          {caption}
        </Box>
      )}
    </React.Fragment>
  ) : null
}

export default StoryblokImage

import * as React from 'react'
import ReactMarkdown from 'react-markdown'
import remarkBreaks from 'remark-breaks'

import * as styles from './Markdown.module.scss'

type Props = {
  className?: any
  noParagraphs?: boolean
  children: any
  escapeHtml?: boolean
  readMore?: boolean
}

type MarkdownProps = {
  className: any
  disallowedTypes?: any
  noParagraphs?: boolean
  plugins: any
  transformLinkUri: any
  linkTarget: any
  unwrapDisallowed?: boolean
  escapeHtml?: boolean
}

const linkTarget = (url = '') => {
  let returnTarget
  const localUrl = url.split('?').shift()
  const isMail = url && url.split(':')[0] === 'mailto'
  const isPhone = url && url.split(':')[0] === 'tel'

  if ((localUrl && localUrl.substr(-4, 4) === '.pdf') || isMail || isPhone) {
    returnTarget = '_blank'
  }

  return returnTarget
}

const LinkRenderer = (props: any) => {
  const { children, node } = props
  const { url } = node

  const target = linkTarget(url)

  return (
    <a
      href={url}
      target={target}
      rel={target === '_blank' ? 'noopener noreferrer' : ''}
    >
      {children}
    </a>
  )
}

const Markdown = ({
  className = '',
  noParagraphs = false,
  escapeHtml = false,
  children,
}: Props): JSX.Element => {
  const plugins = [remarkBreaks]

  // const markdownProps: MarkdownProps = {
  //   className,
  //   plugins,
  //   escapeHtml,
  // }

  if (noParagraphs) {
    markdownProps.disallowedTypes = ['paragraph']
    markdownProps.unwrapDisallowed = true
  }

  return (
    <div className={styles.markdown}>
      <ReactMarkdown>{children}</ReactMarkdown>
    </div>
  )
}

export default Markdown

// extracted by mini-css-extract-plugin
export var overlay = "Information-module--overlay--10c7D";
export var panel = "Information-module--panel--263om";
export var loaded = "Information-module--loaded--1P33L";
export var preview = "Information-module--preview--1ZvHF";
export var title = "Information-module--title--11gol";
export var text = "Information-module--text--3g5ik";
export var dot = "Information-module--dot--1AZXg";
export var active = "Information-module--active--20RGg";
export var appleHighlight = "Information-module--appleHighlight--z5g3B";
export var spin = "Information-module--spin--3JX3o";
import React, { useState, useEffect } from 'react'
import { Flex, Box } from 'reflexbox'
import * as styles from './Information.module.scss'
import classnames from 'classnames'
import Button from 'src/components/UI/Button/Button'
import 'keen-slider/keen-slider.min.css'
import { useKeenSlider } from 'keen-slider/react' // import from 'keen-slider/react.es' for to get an ES module
import { useRecoilState } from 'recoil'
import { activePopUpState, onBoardingState } from 'src/recoil/atoms'

const Information = ({ preview, blok }: any) => {
  const [activePopUp, setActivePopUp] = useRecoilState(activePopUpState)
  const [onBoarding, setOnBoarding] = useRecoilState(onBoardingState)
  const [loading, setLoading] = useState(false)
  const [activeIndex, setActiveIndex] = useState(0)

  const { steps } = blok || {}

  const [refCallback, slider] = useKeenSlider({
    slideChanged() {
      setActiveIndex(slider?.current?.track?.details?.abs)
    },
  })

  useEffect(() => {
    if (activePopUp) {
      // setLoading(true)
      setTimeout(() => {
        setLoading(true)
      }, 200)
    }
    if (!onBoarding && !activePopUp) {
      setTimeout(() => {
        setActivePopUp(true)
      }, 1650)
    }
  }, [activePopUp])

  const handleClose = () => {
    setLoading(false)
    setOnBoarding(true)
    setTimeout(() => {
      setActivePopUp(false)
    }, 200)
  }

  return activePopUp || preview ? (
    <React.Fragment>
      <Box
        className={classnames(
          styles.overlay,
          loading ? styles.loaded : '',
          preview ? styles.preview : ''
        )}
        onClick={() => handleClose()}
      ></Box>

      <Flex
        className={classnames(
          styles.panel,
          loading ? styles.loaded : '',
          preview ? styles.preview : ''
        )}
        justifyContent="space-between"
        flexDirection="column"
      >
        <Flex
          height="110px"
          justifyContent="flex-end"
          alignItems="center"
          paddingX={10}
        >
          <Button onClick={() => handleClose()} style="small">
            Close
          </Button>
        </Flex>

        <Flex ref={refCallback} className="keen-slider">
          {steps?.length > 0 &&
            steps.map((step: any, index: number) => {
              const { title, text } = step
              return (
                <Box
                  key={`slide-${index}`}
                  paddingX={10}
                  className="keen-slider__slide"
                >
                  {title && (
                    <Box className={styles.title} marginBottom={6}>
                      {title}
                    </Box>
                  )}
                  {text && <Box className={styles.text}>{text}</Box>}
                </Box>
              )
            })}
        </Flex>
        <Box
          height="110px"
          justifyContent="center"
          flexDirection="column"
          marginBottom={'70px'}
        >
          <Flex justifyContent="center">
            {steps?.length > 0 &&
              steps.map((step, index) => {
                return (
                  <Box
                    key={`slide-dot-${index}`}
                    as="button"
                    onClick={() => slider?.current?.moveToIdx(index)}
                    className={classnames(
                      styles.dot,
                      index === activeIndex ? styles.active : ''
                    )}
                  >
                    ✦
                  </Box>
                )
              })}
          </Flex>
        </Box>
      </Flex>
    </React.Fragment>
  ) : null
}

export default Information

import React, { useState } from 'react'
import { Box, Flex } from 'reflexbox'
import Badge from '../Badge/Badge'
import Image from '../UI/Image/Image'
import * as styles from './ListCard.module.scss'
import { getStreamStatus } from 'src/helpers/streamStatus'
import classnames from 'classnames'
import { useRecoilState } from 'recoil'
import { activeStreamState } from 'src/recoil/atoms'
import LinkComponent from '../LinkComponent/LinkComponent'

const CardContent = ({
  stream,
  wide,
  animate,
  color,
}: {
  stream: any
  wide: boolean
  animate: boolean
  color?: boolean
}) => {
  const { image, title, start_time, end_time, country_code } = stream
  const { isLive, status } = getStreamStatus(start_time, end_time)
  return (
    <Flex
      alignItems={'flex-start'}
      width={1}
      flexDirection={'column'}
      className={styles.wrapper}
    >
      {color && (
        <Flex
          as="span"
          justifyContent="center"
          alignItems="center"
          className={styles.play}
        >
          <img src="/images/play.svg" />
        </Flex>
      )}
      <Box
        width={1}
        marginBottom={4}
        className={classnames(
          styles.imageWrapper,
          wide ? styles.wide : '',
          color ? styles.color : '',
          animate ? styles.animate : ''
        )}
      >
        {!color && (
          <Box className={classnames(styles.badge, wide ? styles.wide : '')}>
            {status && <Badge live={isLive} title={status} />}
          </Box>
        )}
        {image && image.filename && <Image src={image.filename} alt={title} />}
      </Box>

      {!color && (
        <Box
          className={styles.title}
          as="span"
        >{`${title} (${country_code})`}</Box>
      )}
    </Flex>
  )
}

const ListCard = ({
  stream,
  wide,
  isFirst = false,
  color = false,
}: {
  stream: any
  wide: boolean
  isFirst?: boolean
  color?: boolean
}) => {
  const [activeStream, setActiveStream] = useRecoilState(activeStreamState)
  const [animate, setAnimate] = useState(false)
  const { doors_stream, start_time, end_time } = stream

  const { isLive, hasEnded } = getStreamStatus(start_time, end_time)
  return doors_stream && !hasEnded && isFirst ? (
    <LinkComponent
      to={doors_stream}
      target="_self"
      onClick={() => {
        if (!animate) {
          setAnimate(true)
        }
      }}
      onAnimationEnd={() => {
        if (animate) {
          setAnimate(false)
        }
      }}
    >
      <CardContent
        stream={stream}
        wide={wide}
        animate={animate}
        color={color}
      />
    </LinkComponent>
  ) : (
    <Flex
      width={1}
      as="button"
      onClick={() => {
        if (!activeStream && setActiveStream) {
          setActiveStream(stream)
        }
        if (!animate) {
          setAnimate(true)
        }
      }}
      onAnimationEnd={() => {
        if (animate) {
          setAnimate(false)
        }
      }}
    >
      <CardContent
        stream={stream}
        wide={wide}
        animate={animate}
        color={color}
      />
    </Flex>
  )
}

export default ListCard

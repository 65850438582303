import { useState, useEffect } from 'react'

/* eslint-disable */
export const useVideoPlayer = (videoElement: any) => {
   const [playerState, setPlayerState] = useState({
      isPlaying: false,
      progress: 0,
      speed: 1,
      isMuted: false,
   })

   const togglePlay = () => {
      
      if(videoElement?.current){
         playerState.isPlaying
         ? videoElement.current.pause()
         : videoElement.current.play()
      }
      setPlayerState({
         ...playerState,
         isPlaying: !playerState.isPlaying,
      })
   }
   const setPaused = () => {
      setPlayerState({
         ...playerState,
         isPlaying: false,
      })
   }
   // useEffect(() => {
   //    if (videoElement?.current) {
   //       console.log("here", playerState.isPlaying);
         
   //       playerState.isPlaying
   //          ? videoElement.current.pause()
   //          : videoElement.current.play()
   //    }
   // }, [playerState.isPlaying, videoElement])

   const handleOnTimeUpdate = () => {
      const progress =
         (videoElement.current.currentTime / videoElement.current.duration) *
         100
      setPlayerState({
         ...playerState,
         progress,
      })
   }

   const handleVideoProgress = (event: any) => {
      const manualChange = Number(event.target.value)
      videoElement.current.currentTime =
         (videoElement.current.duration / 100) * manualChange
      setPlayerState({
         ...playerState,
         progress: manualChange,
      })
   }

   const handleVideoSpeed = (event: any) => {
      const speed = Number(event.target.value)
      videoElement.current.playbackRate = speed
      setPlayerState({
         ...playerState,
         speed,
      })
   }

   const toggleMute = () => {
      setPlayerState({
         ...playerState,
         isMuted: !playerState.isMuted,
      })
   }

   useEffect(() => {
      if (videoElement?.current) {
         playerState.isMuted
            ? (videoElement.current.muted = true)
            : (videoElement.current.muted = false)
      }
   }, [playerState.isMuted, videoElement])

   return {
      playerState,
      togglePlay,
      handleOnTimeUpdate,
      handleVideoProgress,
      handleVideoSpeed,
      toggleMute,
      setPaused
   }
}

export const useKeyPress = (targetKey: any) => {
   // State for keeping track of whether key is pressed
   const [keyPressed, setKeyPressed] = useState<boolean>(false)
   // If pressed key is our target key then set to true
   function downHandler({ key }: { key: string }) {
      if (key === targetKey) {
         setKeyPressed(true)
      }
   }
   // If released key is our target key then set to false
   const upHandler = ({ key }: { key: string }) => {
      if (key === targetKey) {
         setKeyPressed(false)
      }
   }
   // Add event listeners
   useEffect(() => {
      window.addEventListener('keydown', downHandler)
      window.addEventListener('keyup', upHandler)
      // Remove event listeners on cleanup
      return () => {
         window.removeEventListener('keydown', downHandler)
         window.removeEventListener('keyup', upHandler)
      }
   }, []) // Empty array ensures that effect is only run on mount and unmount
   return keyPressed
}
/* eslint-enable */

// extracted by mini-css-extract-plugin
export var siteWrapper = "PageHome-module--siteWrapper--3Xulf";
export var hide = "PageHome-module--hide--zSiAB";
export var logotype = "PageHome-module--logotype--ebPsY";
export var rose_1 = "PageHome-module--rose_1--1o3aV";
export var rose_2 = "PageHome-module--rose_2--ljdnx";
export var bee = "PageHome-module--bee--nAgAX";
export var butterfly = "PageHome-module--butterfly--3FMLF";
export var title = "PageHome-module--title--2BCuR";
export var appleHighlight = "PageHome-module--appleHighlight--18VMi";
export var fadeIn = "PageHome-module--fadeIn--2j-MH";
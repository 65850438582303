import React, { useEffect, useRef, useState } from 'react'
import { Box, Flex } from 'reflexbox'
import Image from 'src/components/UI/Image/Image'
import YouTube from 'react-youtube'
import * as styles from './VimeoVideo.module.scss'
import classnames from 'classnames'
import isNode from 'is-node'
import { getStreamStatus } from 'src/helpers/streamStatus'
import { useRecoilState } from 'recoil'
import { activeStreamState } from 'src/recoil/atoms'
import Vimeo from '@u-wave/react-vimeo'

const VimeoVideo = () => {
  const [activeStream, setActiveStream] = useRecoilState(activeStreamState)

  const { vimeo_source = '', title, image, start_time, end_time } =
    activeStream || {}
  const { hasStarted = false, status = '' } = start_time
    ? getStreamStatus(start_time, end_time)
    : {}

  const wrapperRef = useRef(null)
  const [open, setOpen] = useState(false)
  const [load, setLoaded] = useState(false)

  const observeHandler = (entries: any) => {
    for (const entry of entries) {
      if (entry.isIntersecting && !load) {
        setLoaded(true)
      }
    }
  }

  const observer = !isNode
    ? new IntersectionObserver(observeHandler, {
        rootMargin: '0px 0px 0px 0px',
      })
    : null

  useEffect(() => {
    if (activeStream) {
      setTimeout(() => {
        setOpen(true)
      }, 50)
    }
  }, [activeStream])

  useEffect(() => {
    if (wrapperRef && wrapperRef.current) {
      observer?.observe(wrapperRef.current)
    }
    return () => {
      observer?.disconnect()
    }
  }, [])

  const onVideoReady = (e: any) => {
    const el = document.getElementById('content-wrapper')
    if (el) {
      setTimeout(() => {
        el.style.opacity = '1'
      }, 700)
    }
  }

  const handleClose = () => {
    setOpen(false)
    setTimeout(() => {
      setActiveStream(null)
    }, 950)
  }

  return (
    <React.Fragment>
      <Box
        className={classnames(
          styles.overlay,
          open ? styles.open : '',
          !activeStream ? styles.hidden : ''
        )}
      />
      <Flex
        className={classnames(
          styles.wrapper,
          open ? styles.open : '',
          !activeStream ? styles.hidden : ''
        )}
        flexDirection="column"
      >
        <Flex
          justifyContent={'center'}
          alignItems="center"
          className={styles.overlayHeader}
          height="115px"
        >
          <Box
            padding={6}
            as="button"
            onClick={() => {
              if (open) {
                handleClose()
              }
            }}
            className={styles.back}
          >
            <img src="/images/pilthin.svg" />
          </Box>
          <Box className={styles.videoTitle} as="span">
            {title}
          </Box>
        </Flex>
        <Box>
          <Box ref={wrapperRef} width={1} className={styles.videoWrapper}>
            <Box
              id="content-wrapper"
              className={classnames(
                styles.contentWrapper,
                !hasStarted ? styles.noVideo : ''
              )}
            >
              {!hasStarted ? (
                <Box className={classnames(styles.placeholder)}>
                  {image && image.filename && (
                    <Image src={image.filename} alt={title} />
                  )}
                </Box>
              ) : vimeo_source ? (
                <Box className={classnames(styles.video)}>
                  <Vimeo
                    video={vimeo_source}
                    loop={false}
                    width={'100%'}
                    showByline={false}
                    showPortrait={false}
                    showTitle={false}
                    autoplay={true}
                    controls={true}
                    color="#fce6b2"
                    onReady={onVideoReady}
                  />
                </Box>
              ) : null}
            </Box>
          </Box>
          <Flex
            className={styles.status}
            padding={6}
            justifyContent="center"
            alignItems="center"
          >
            <Box marginTop="4px">{status}</Box>
          </Flex>
        </Box>
      </Flex>
    </React.Fragment>
  )
}

export default VimeoVideo

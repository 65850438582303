import React from 'react'
import { Box } from 'reflexbox'
import ListCard from '../ListCard/ListCard'
import { Column, Container, Row } from '../UI/Grid/Grid'
import * as styles from './PreviousList.module.scss'
import { useIntl } from 'react-intl'

const PreviousList = ({ streams }: { streams: any[] }) => {
  const { formatMessage } = useIntl()

  return (
    <Container paddingTop={[8]}>
      <Row>
        <Column width={1}>
          <Box marginBottom={8} className={styles.label} as="h3">
            {formatMessage({ id: 'previous' })}
          </Box>
        </Column>
        {streams
          .sort(
            (a: any, b: any) =>
              new Date(b.start_time).getTime() -
              new Date(a.start_time).getTime()
          )
          .map((stream: any) => (
            <Column
              key={`previous-${stream._uid}`}
              marginBottom={[10]}
              width={1 / 3}
            >
              <ListCard stream={stream} upcoming={false} />
            </Column>
          ))}
      </Row>
    </Container>
  )
}

export default PreviousList

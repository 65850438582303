import React, { useState } from 'react'
import { Flex, Box } from 'reflexbox'
import * as styles from './Gallery.module.scss'
import classnames from 'classnames'
import 'keen-slider/keen-slider.min.css'
import { useKeenSlider } from 'keen-slider/react' // import from 'keen-slider/react.es' for to get an ES module
import Components from 'src/storyblok/Components'

const Gallery = ({ blok }: any) => {
  const [activeIndex, setActiveIndex] = useState(0)

  const { images, caption } = blok || {}

  const [refCallback, slider] = useKeenSlider({
    slideChanged() {
      setActiveIndex(slider?.current?.track?.details?.abs)
    },
  })

  return (
    <Box className={styles.wrapper}>
      <Box className={styles.sliderWrapper}>
        <Box className={classnames(styles.slider)}>
          <Flex
            ref={refCallback}
            className={classnames('keen-slider')}
            width={1}
          >
            {images?.length > 0 &&
              images.map((blok: any) => {
                return (
                  <Box
                    key={blok?._uid}
                    width={1}
                    className="keen-slider__slide"
                  >
                    {React.createElement(Components(blok.component), {
                      key: blok._uid,
                      blok: blok,
                      className:
                        blok?.component === 'image'
                          ? styles.imageWrapper
                          : styles.videoWrapper,
                    })}
                  </Box>
                )
              })}
          </Flex>
        </Box>
      </Box>
      {caption && (
        <Box className={styles.caption} marginTop={4}>
          {caption}
        </Box>
      )}
      <Box
        className={styles.dots}
        justifyContent="center"
        flexDirection="column"
      >
        <Flex justifyContent="center">
          {images?.length > 0 &&
            images.map((step, index) => {
              return (
                <Box
                  key={`slide-dot-${index}`}
                  as="button"
                  onClick={() => slider?.current?.moveToIdx(index)}
                  className={classnames(
                    styles.dot,
                    index === activeIndex ? styles.active : ''
                  )}
                >
                  ✦
                </Box>
              )
            })}
        </Flex>
      </Box>
    </Box>
  )
}

export default Gallery

import React, { useState } from 'react'
import { Box, Flex } from 'reflexbox'
import Badge from '../Badge/Badge'
import Button from '../UI/Button/Button'
import Image from '../UI/Image/Image'
import * as styles from './Hero.module.scss'
import { getStreamStatus } from 'src/helpers/streamStatus'
import { Container } from '../UI/Grid/Grid'
import classnames from 'classnames'
import { getLinkAttributes } from 'src/helpers/getLinkAttributes'
import { useIntl } from 'react-intl'
import { useRecoilState } from 'recoil'
import { activeStreamState } from 'src/recoil/atoms'
import LinkComponent from '../LinkComponent/LinkComponent'

const HeroContent = ({
  highlight,
  placeholder,
  graphics,
}: {
  highlight: any
  placeholder: any
  graphics?: any
}) => {
  const [, setActiveStream] = useRecoilState(activeStreamState)
  const { formatMessage } = useIntl()

  // placeholder
  const { image: placeholderImage, title: placeholderTitle, link } =
    placeholder || {}
  const { url = '', title: linkTitle = '' } =
    link?.length > 0 ? getLinkAttributes(link[0]) : {}

  // highlight
  const {
    image,
    title,
    current_highlight,
    start_time,
    end_time,
    doors_stream,
  } = highlight || {}

  const { hasStarted = false, isLive = false, status = '' } =
    start_time && end_time ? getStreamStatus(start_time, end_time) : {}

  const { rose_1, bee }: any = graphics ? graphics : {}

  return (
    <React.Fragment>
      {current_highlight && image && image.filename && (
        <Image
          src={image.filename}
          className={styles.background}
          alt={title}
          sizes="100vw"
        />
      )}
      {!current_highlight && placeholderImage && placeholderImage.filename && (
        <Image
          src={placeholderImage.filename}
          className={styles.background}
          alt={title}
          sizes="100vw"
        />
      )}
      {bee && bee.filename && (
        <Image src={bee.filename} alt={bee.title} className={styles.bee} />
      )}
      <Flex
        alignItems={'center'}
        justifyContent="center"
        className={styles.content}
      >
        <Container>
          <Flex alignItems={'center'} flexDirection="column">
            {title ? (
              <React.Fragment>
                <Box>
                  <Flex marginBottom={6} width={1} justifyContent={'flex-end'}>
                    <Badge live={isLive} title={status} />
                  </Flex>
                  <Box
                    marginBottom={3}
                    as="h2"
                    className={classnames(
                      styles.title,
                      title ? styles.uppercase : ''
                    )}
                  >
                    {title}
                  </Box>
                </Box>
                {hasStarted && (
                  <React.Fragment>
                    {isLive && doors_stream ? (
                      <Button href={doors_stream}>
                        {formatMessage({ id: 'play' })}
                      </Button>
                    ) : (
                      <Button
                        onClick={() => {
                          if (setActiveStream) {
                            setActiveStream(highlight)
                          }
                        }}
                      >
                        {formatMessage({ id: 'play' })}
                      </Button>
                    )}
                  </React.Fragment>
                )}
              </React.Fragment>
            ) : placeholderTitle ? (
              <React.Fragment>
                <Box>
                  <Box marginBottom={3} as="h2" className={styles.title}>
                    {placeholderTitle}
                  </Box>
                </Box>
                {url && linkTitle && <Button href={url}>{linkTitle}</Button>}
              </React.Fragment>
            ) : (
              <Flex
                width={1}
                className={styles.rosendalTitle}
                flexDirection="column"
                as="h1"
                paddingBottom={[6, null, null, 0]}
              >
                <Box className={styles.firstLetter} as="span">
                  {'Rosendal'}
                </Box>
                {rose_1 && rose_1.filename && (
                  <Image
                    src={rose_1.filename}
                    alt={rose_1.title}
                    className={styles.rose_1}
                  />
                )}
                <Flex justifyContent={'center'}>
                  <Box
                    marginRight={'0.1em'}
                    className={styles.secondLetter}
                    as="span"
                  >
                    {'Garden'}
                  </Box>
                  <Box
                    marginLeft={'0.1em'}
                    className={styles.thirdLetter}
                    as="span"
                  >
                    {'Party'}
                  </Box>
                </Flex>
              </Flex>
            )}
          </Flex>
        </Container>
      </Flex>
    </React.Fragment>
  )
}

const Hero = ({
  highlight,
  placeholder,
  graphics,
}: {
  highlight: any
  placeholder: any
  graphics?: any
}) => {
  const [activeStream, setActiveStream] = useRecoilState(activeStreamState)
  const [animate, setAnimate] = useState(false)

  // highlight
  const { start_time, end_time, current_highlight, doors_stream } =
    highlight || {}
  const { hasEnded = false } =
    start_time && end_time ? getStreamStatus(start_time, end_time) : {}
  return doors_stream && !hasEnded ? (
    <LinkComponent
      to={doors_stream}
      className={classnames(styles.wrapper, animate ? styles.animate : '')}
      target="_self"
      onClick={() => {
        if (!animate) {
          setAnimate(true)
        }
      }}
      onAnimationEnd={() => {
        if (animate) {
          setAnimate(false)
        }
      }}
    >
      <HeroContent
        highlight={highlight}
        placeholder={placeholder}
        graphics={graphics}
      />
    </LinkComponent>
  ) : (
    <Box
      className={classnames(styles.wrapper, animate ? styles.animate : '')}
      onClick={() => {
        if (current_highlight && !activeStream) {
          if (!animate) {
            setAnimate(true)
          }
          if (setActiveStream) {
            setActiveStream(highlight)
          }
        }
      }}
      onAnimationEnd={() => {
        if (animate) {
          setAnimate(false)
        }
      }}
    >
      <HeroContent
        highlight={highlight}
        placeholder={placeholder}
        graphics={graphics}
      />
    </Box>
  )
}

export default Hero

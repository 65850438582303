import React from 'react'
import SEO from 'src/components/SEO/SEO'
import { getSeo } from 'src/helpers/getSeo'

export interface SEOPageProps {
  storyblokData?: any
  customData?: { title?: string; description?: string; image?: string }
  lang?: 'sv' | 'en'
  path?: string
}

const SEOPage: React.FC<SEOPageProps> = ({
  storyblokData,
  customData,
  lang = 'sv',
  path,
}) => {
  const { seo_title, seo_description, seo_image } = getSeo(
    storyblokData,
    customData
  )
  if (path === 'home') {
    path = ''
  }
  if (path === 'en/home') {
    path = ''
  }
  if (path?.startsWith('en/')) {
    path = path.replace('en/', '')
  }

  return (
    <SEO
      asPath={path}
      title={seo_title}
      description={seo_description}
      image={seo_image}
      lang={lang}
    />
  )
}

export default SEOPage

// extracted by mini-css-extract-plugin
export var overlay = "VimeoVideo-module--overlay--1Mj3n";
export var open = "VimeoVideo-module--open--iJf9P";
export var hidden = "VimeoVideo-module--hidden--e8Vrc";
export var wrapper = "VimeoVideo-module--wrapper--NjWgX";
export var back = "VimeoVideo-module--back--2JZ2I";
export var videoTitle = "VimeoVideo-module--videoTitle--3a9d-";
export var status = "VimeoVideo-module--status--2YjJT";
export var videoWrapper = "VimeoVideo-module--videoWrapper--2zcyA";
export var contentWrapper = "VimeoVideo-module--contentWrapper--3xXLC";
export var noVideo = "VimeoVideo-module--noVideo--x3ZDm";
export var placeholder = "VimeoVideo-module--placeholder--3XhNA";
export var video = "VimeoVideo-module--video--1LaFx";
export var appleHighlight = "VimeoVideo-module--appleHighlight--A7Phz";
// extracted by mini-css-extract-plugin
export var wrapper = "Gallery-module--wrapper--NNoJZ";
export var sliderWrapper = "Gallery-module--sliderWrapper--3Q1I8";
export var slider = "Gallery-module--slider--1N-DE";
export var videoWrapper = "Gallery-module--videoWrapper--1-zk6";
export var imageWrapper = "Gallery-module--imageWrapper--3Wxk1";
export var caption = "Gallery-module--caption--9943l";
export var dots = "Gallery-module--dots--1t4dr";
export var dot = "Gallery-module--dot--V0Otl";
export var active = "Gallery-module--active--2WWY0";
export var appleHighlight = "Gallery-module--appleHighlight--nsgiC";
// Page types
import PageHome from './Pages/PageHome/PageHome'

// Global Modules
import Footer from './Global/Footer/Footer'
import Information from './Global/Information/Information'

// Modules
import CuratedContent from './Modules/CuratedContent/CuratedContent'
import StoryblokImage from './Components/StoryblokImage/StoryblokImage'
import StoryblokVideo from './Components/StoryblokVideo/StoryblokVideo'
import Gallery from './Modules/Gallery/Gallery'

// Components
import ComponentNotFound from './ComponentNotFound'

// Modules
const ComponentList: any = {
  // Page types
  page: PageHome,

  // Global Components
  footer: Footer,
  information: Information,

  // Modules
  curated_content: CuratedContent,
  gallery: Gallery,

  // Components
  image: StoryblokImage,
  video: StoryblokVideo,
}

const Components = (type: string) => {
  if (typeof ComponentList[type] === 'undefined') {
    return ComponentNotFound
  }
  return ComponentList[type]
}

export default Components

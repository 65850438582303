import React from 'react'
import { Box } from 'reflexbox'
import ListCard from '../ListCard/ListCard'
import { Column, Container, Row } from '../UI/Grid/Grid'
import * as styles from './HighlightedList.module.scss'
import { useIntl } from 'react-intl'
import Image from 'src/components/UI/Image/Image'

const HighlightedList = ({
  streams,
  graphics,
  title,
}: {
  streams: any[]
  graphics?: any[]
  title?: string
}) => {
  const { formatMessage } = useIntl()
  const { rose_1, rose_2, rose_3 }: any = graphics ? graphics : {}
  return (
    <Box as="section" className={styles.wrapper} marginBottom={[6]}>
      {rose_1 && rose_1.filename && (
        <Image
          src={rose_1.filename}
          alt={rose_1.title}
          className={styles.rose_1}
        />
      )}
      {rose_2 && rose_2.filename && (
        <Image
          src={rose_2.filename}
          alt={rose_2.title}
          className={styles.rose_2}
        />
      )}
      {rose_3 && rose_3.filename && (
        <Image
          src={rose_3.filename}
          alt={rose_3.title}
          className={styles.rose_3}
        />
      )}
      <Container>
        <Row>
          <Column width={1}>
            <Box marginBottom={6} className={styles.label} as="h3">
              {title}
            </Box>
          </Column>
          {streams
            .sort(
              (a: any, b: any) =>
                new Date(b.start_time).getTime() -
                new Date(a.start_time).getTime()
            )
            .map((stream: any) => (
              <Column
                key={`previous-${stream._uid}`}
                marginBottom={[10]}
                width={1}
              >
                <ListCard stream={stream} wide={true} color />
              </Column>
            ))}
        </Row>
      </Container>
    </Box>
  )
}

export default HighlightedList

import React from 'react'
import { Box, Flex } from 'reflexbox'
import * as styles from './Badge.module.scss'

const Badge = ({ live, title }: { live?: boolean; title?: string }) => {
  return (
    <Flex
      height="38px"
      alignItems={'center'}
      className={live ? [styles.badge, styles.active].join(' ') : styles.badge}
      paddingX={'10px'}
    >
      {live && (
        <Box
          alignItems={'center'}
          justifyContent="center"
          marginRight={2}
          className={styles.outerCircle}
        >
          <Box className={styles.single} />
          <Flex
            alignItems={'center'}
            justifyContent="center"
            className={styles.cirlce}
          >
            <div />
          </Flex>
        </Box>
      )}
      {live ? (
        <Box as="span">Live Now</Box>
      ) : title ? (
        <Box as="span">{title}</Box>
      ) : null}
    </Flex>
  )
}

export default Badge

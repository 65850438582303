export const getSeo = (
   seoStoryblok: any,
   customSeo?: { title?: string; description?: string; image?: string },
) => {
   const isArray = seoStoryblok && seoStoryblok.length > 0
   const seoData = isArray ? seoStoryblok[0] : seoStoryblok ? seoStoryblok : {}

   const {
      seo_description = '',
      seo_title = '',
      seo_image = '',
   } = seoData ? seoData : {}
   const { description, title, image } = customSeo || {}

   return {
      seo_description: seo_description || description || '',
      seo_title: seo_title || title || '',
      seo_image: seo_image?.length > 0
         ? seo_image
         : seo_image.filename && seo_image?.filename?.length > 0
         ? seo_image?.filename
         : image || '',
   }
}  
import { atom } from 'recoil'
import isNode from "is-node"


const localStorageEffect =
  (key: string) =>
  ({ setSelf, onSet, ...rest }: any) => {
    if (!isNode) {
      const savedValue = localStorage.getItem(key)
      if (savedValue != null) {
        setSelf(JSON.parse(savedValue))
      }

      onSet((newValue: any) => {
        localStorage.setItem(key, JSON.stringify(newValue))
      })
    }
  }

export const activeStreamState = atom({
  key: 'activeStream',
  default: null as any,
})

export const activePopUpState = atom({
  key: 'activePopUp',
  default: null as any,
})

export const onBoardingState = atom({
  key: 'onBoarding',
  default: null as any,
  effects_UNSTABLE: [localStorageEffect('onBoarding')],
})




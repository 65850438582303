import React, { useState } from 'react'
import SbEditable from 'storyblok-react'
import * as styles from './PageHome.module.scss'
import SEOPage from 'src/components/SEOPage/SEOPage'
import Hero from 'src/components/Hero/Hero'
import UpcomingList from 'src/components/UpcomingList/UpcomingList'
import PreviousList from 'src/components/PreviousList/PreviousList'
import HighlightedList from 'src/components/HighlightedList/HighlightedList'
import VimeoVideo from 'src/components/VimeoVideo/VimeoVideo'
import { Box, Flex } from 'reflexbox'
import CuratedContent from 'src/storyblok/Modules/CuratedContent/CuratedContent'
import About from 'src/components/About/About'
import { useRecoilState } from 'recoil'
import { activeStreamState } from 'src/recoil/atoms'
import { Container } from 'src/components/UI/Grid/Grid'
import Image from 'src/components/UI/Image/Image'
const PageHome = ({ blok, full_slug, ...rest }: any): JSX.Element => {
  const [activeStream] = useRecoilState(activeStreamState)
  const {
    seo_data,
    placeholder,
    streams,
    previous_streams,
    highlighted_streams,
    graphics,
    highlighted_streams_title,
  } = blok

  const { rose_1, rose_2, bee, butterfly }: any = graphics ? graphics : {}

  const hero = placeholder?.length > 0 ? placeholder[0] : null

  const allStreams: any[] = streams.concat(previous_streams)
  const upcomingStreams: any[] = streams
  const previousStreams: any[] = previous_streams
  const highlightedStreams: any[] = highlighted_streams
  const highlight =
    allStreams?.length > 0
      ? allStreams.find((stream: any) => stream.current_highlight === true)
      : null

  // streams.forEach((stream: any) => {
  //   const { hasStarted } = getStreamStatus(stream.start_time)
  //   if (hasStarted) {
  //     previousStreams.push(stream)
  //   } else {
  //     upcomingStreams.push(stream)
  //   }
  // })

  return (
    <SbEditable content={blok}>
      <React.Fragment>
        <SEOPage path={full_slug} storyblokData={seo_data} />
        {activeStream && <VimeoVideo />}
        <Flex flexDirection={'column'} className={styles.siteWrapper} width={1}>
          {(highlight || hero) && (
            <Hero
              highlight={highlight}
              placeholder={hero}
              graphics={graphics}
            />
          )}
          {upcomingStreams?.length > 0 && (
            <UpcomingList streams={upcomingStreams} />
          )}
          {previousStreams?.length > 0 && (
            <PreviousList streams={previousStreams} />
          )}
        </Flex>
        <CuratedContent blok={blok} graphics={graphics} />
        {highlightedStreams?.length > 0 && (
          <HighlightedList
            title={highlighted_streams_title}
            streams={highlightedStreams}
            graphics={graphics}
          />
        )}
        <About blok={blok} />
        <Box as="section" className={styles.logotype}>
          {rose_1 && rose_1.filename && (
            <Image
              src={rose_1.filename}
              alt={rose_1.title}
              className={styles.rose_1}
            />
          )}
          {rose_2 && rose_2.filename && (
            <Image
              src={rose_2.filename}
              alt={rose_2.title}
              className={styles.rose_2}
            />
          )}
          {bee && bee.filename && (
            <Image src={bee.filename} alt={bee.title} className={styles.bee} />
          )}
          {butterfly && butterfly.filename && (
            <Image
              src={butterfly.filename}
              alt={butterfly.title}
              className={styles.butterfly}
            />
          )}
          <Container>
            <Box paddingY={14} width={1} className={styles.title}>
              <img src="/images/logotype.svg" />
            </Box>
          </Container>
        </Box>
      </React.Fragment>
    </SbEditable>
  )
}

export default PageHome

import React from 'react'
import { Box } from 'reflexbox'
import { Container } from 'src/components/UI/Grid/Grid'
import * as styles from './About.module.scss'
import Markdown from '../UI/Markdown/Markdown'
const About = ({ blok }: { blok: any }) => {
  const { about_title, about_text } = blok || {}
  console.log(about_title)
  return about_title && about_text ? (
    <React.Fragment>
      <Container className={styles.wrapper}>
        {about_title && (
          <Box className={styles.label} as="h3" paddingBottom={6} width={1}>
            <Box
              width={7 / 12}
              dangerouslySetInnerHTML={{ __html: about_title }}
            />
          </Box>
        )}
        {about_text && (
          <Box className={styles.text} as="h3" width={1}>
            <Markdown>{about_text}</Markdown>
          </Box>
        )}
      </Container>
    </React.Fragment>
  ) : null
}

export default About

import React from 'react'
import { Box, Flex } from 'reflexbox'
import { Container } from 'src/components/UI/Grid/Grid'
import * as styles from './CuratedContent.module.scss'
import SbEditable from 'storyblok-react'
import { useIntl } from 'react-intl'
import Components from 'src/storyblok/Components'
import Image from 'src/components/UI/Image/Image'
const CuratedContent = ({ blok, graphics }: { blok: any; graphics: any }) => {
  const { formatMessage } = useIntl()
  const { curated_content, curated_content_title } = blok || {}

  const { rose_2, rose_6 }: any = graphics ? graphics : {}
  return (
    <SbEditable content={blok}>
      {curated_content?.length > 0 && (
        <Box
          as="section"
          paddingTop={6}
          marginBottom={16}
          className={styles.wrapper}
        >
          {rose_2 && rose_2.filename && (
            <Image
              src={rose_2.filename}
              alt={rose_2.title}
              className={styles.rose_2}
            />
          )}
          {rose_6 && rose_6.filename && (
            <Image
              src={rose_6.filename}
              alt={rose_6.title}
              className={styles.rose_6}
            />
          )}
          <Container>
            <Box className={styles.label} as="h3" width={1} paddingBottom={6}>
              {curated_content_title}
            </Box>
          </Container>
          <Flex className={styles.slider}>
            {curated_content.map((blok: any, index: number) => (
              <Box
                key={`content-${blok._uid}`}
                width={2.5 / 6}
                flex="0 0 auto"
                marginRight={6}
                marginLeft={index === 0 ? 10 : 0}
              >
                {React.createElement(Components(blok.component), {
                  key: blok._uid,
                  blok: blok,
                  className:
                    blok?.component === 'image'
                      ? styles.imageWrapper
                      : styles.videoWrapper,
                })}
              </Box>
            ))}
          </Flex>
        </Box>
      )}
    </SbEditable>
  )
}

export default CuratedContent


import { format } from 'date-fns'
import  isThisYear  from 'date-fns/isThisYear'
import {getLocalDate} from './dates'
import { useIntl } from 'react-intl'
import enLocale  from 'date-fns/locale/en-US'
import svLocale  from 'date-fns/locale/sv'

export const getStreamStatus = (startTime: string, endTime?:string) => {
  const { locale, formatMessage } = useIntl()
      const currentDate = new Date()
    const startDate = startTime ? getLocalDate(startTime) : currentDate
    const endDate = endTime ? getLocalDate(endTime) : currentDate

    
    const hasStarted =  currentDate.getTime() > startDate.getTime() 
    const hasEnded =  currentDate.getTime() > endDate.getTime() 
    const isLive = currentDate.getTime() > startDate.getTime() && currentDate.getTime() < endDate.getTime()
    const sameYear = isThisYear(startDate)

    const status = !hasStarted ?
       `${formatMessage({ id: 'starts' })} ${format(startDate, 'd MMMM, HH:mm', {locale: locale === 'sv' ? svLocale : enLocale})} (CET)`
        : isLive ? `${formatMessage({ id: 'streaming' })}`
      : sameYear ? format(startDate, 'd MMMM', {locale: locale === 'sv' ? svLocale : enLocale}) : format(startDate, 'yyyy', {locale: locale === 'sv' ? svLocale : enLocale})
    return {status, isLive, hasStarted,hasEnded}
  }
  
import React from 'react'
import * as styles from './Button.module.scss'

import LinkComponent from 'src/components/LinkComponent/LinkComponent'
import classnames from 'classnames'

const Button: React.FC<any> = ({
  onClick,
  href,
  title,
  children,
  style,
  className = '',
  disabled = false,
  type = 'button',
  ariaLabel,
}) => {
  const buttonClassName = classnames(
    styles.button,
    style === 'small' ? styles.small : '',
    className
  )
  return (onClick || type) && !href ? (
    <button
      onClick={onClick}
      type={type}
      aria-label={ariaLabel}
      disabled={disabled}
      className={buttonClassName}
    >
      {children}
    </button>
  ) : href ? (
    <LinkComponent
      to={href}
      onClick={onClick}
      ariaLabel={ariaLabel}
      title={title}
      className={buttonClassName}
    >
      {children}
    </LinkComponent>
  ) : (
    <div className={buttonClassName}>{children}</div>
  )
}

export default Button
